import Api from './index'
const Axios = new Api().axios
const V1 = 'api/v1'
const Get = (data:any) => Axios.get(`${V1}/tracking-product`, { params: data })
const Post = (data:any) => Axios.post(`${V1}/tracking-product`, data)
const Put = (id:number, data:any) => Axios.put(`${V1}/tracking-product/${id}`, data)
const Delete = (id:number) => Axios.delete(`${V1}/tracking-product/${id}`)

export {
  Get, Post, Put, Delete,
}

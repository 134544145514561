export default class {
  private name:string

  constructor(name:string) {
    this.name = name
  }

  get() {
    const item = window.localStorage.getItem(this.name) || '{}'
    return JSON.parse(item)
  }
  set(value:string) {
    window.localStorage.setItem(this.name, value)
  }
  getToken() {
    return this.get().token || ''
  }
  removeToken() {
    window.localStorage.removeItem(this.name)
  }
  getUser() {
    return this.get().user || {}
  }
  getMerchant() {
    return this.getUser().fkTrackingMerchant || {}
  }
  getRoles() {
    return this.get().trackingRoles || []
  }
  getDetailRoles() {
    return this.get().trackingDetailRoles || []
  }
}

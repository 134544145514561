import Api from './index'
const Axios = new Api().axios
const V1 = 'api/v1'
const Get = (data:any) => Axios.get(`${V1}/tracking-sj`, { params: data })
const Post = (data:any) => Axios.post(`${V1}/tracking-sj`, data)
const Put = (id:number, data:any) => Axios.put(`${V1}/tracking-sj/${id}`, data)
const Delete = (id:number) => Axios.delete(`${V1}/tracking-sj/${id}`)
const PostScan = (data:any) => Axios.post(`${V1}/tracking-sj/scan`, data)

export {
  Get, Post, Put, Delete, PostScan
}

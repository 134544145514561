import store from '@/store'
import API from '@/api/identity'
const Api = new API()

export default async (to:any, from:any, next:any) => {
  if (!to.name) {
    next('/')
  } else {
    const exp = await Api.Expired()
    if(!exp) {
      next()
    } else {
      store.dispatch('UserSignout')
      next('/signin')
    }
  }
}

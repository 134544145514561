import rekapSuratJalan from './rekapSuratJalan'
import returSuratJalan from './returSuratJalan'
import suratJalan from './suratJalan'

export default {
  modules: {
    suratJalan,
    returSuratJalan,
    rekapSuratJalan,
  }
}

import Api from './index'
const Axios = new Api().axios
const V1 = 'api/v1'
const UploadImage = async (data:any) => {
  let fd = new FormData()
  fd.append('formFile', data, `image.png`)
  return await Axios.post(`${V1}/upload/image`, fd)
}
export {
  UploadImage
}

import Api from './index'
const Axios = new Api().axios
const V1 = 'api/v1'
const Get = (data:any) => Axios.get(`${V1}/user`, { params: data })
const Post = (data:any) => Axios.post(`${V1}/user`, data)
const Put = (id:number, data:any) => Axios.put(`${V1}/user/${id}`, data)
const Delete = (id:number) => Axios.delete(`${V1}/user/${id}`)
const PutProfile = (data:any) => Axios.put(`${V1}/user/profile`, data)

export {
  Get, Post, Put, Delete,
  PutProfile,
}

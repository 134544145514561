import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/vee-validate'
import './plugins/moment'
import './plugins/numeral'
import './plugins/datetime-picker'
import './plugins/vue-shortkey'
import './plugins/vue-numeric-input'
import './plugins/vue-barcode-scanner'
import './plugins/vue-signature-pad'
import BackComponent from '@/components/Back.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

Vue.config.productionTip = false

Vue.component('AppBack', BackComponent)
Vue.component('AppOverlay', Loading)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    this.$store.dispatch('UserAutoSignin')
  }
}).$mount('#app')










import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Breadcrumbs extends Vue {
  //#region COMPUTED
  get listRole() {
    return this.$store.getters.roles
  }
  get listDetailRole() {
    return this.$store.getters.detailRoles
  }
  get items() {
    const breadcrumbs = []
    const ro = this.$route
    const path = ro.path
    const arr = path.split('/')
    arr.filter(x => x).map(x => {
      let nav:any = this.listRole.find((y:any) => y.route === x)
      if (!nav) nav = this.listDetailRole.find((y:any) => y.route === x)
      breadcrumbs.push({ text: nav ? nav.title : x, disabled: false, href: '' })
    })
    if (!breadcrumbs.length) breadcrumbs.push({ text: 'Home', disabled: false, href: '' })
    return breadcrumbs
  }
  //#endregion
}

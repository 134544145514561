import { required, confirmed, length, email, numeric } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('required', {
  ...required,
  message: 'This field is required'
})
extend('email', {
  ...email,
  message: 'This field must be a valid email'
})
extend('confirmed', {
  ...confirmed,
  message: 'This field confirmation does not match'
})
extend('length', {
  ...length,
  message: 'This field must have 2 options'
})
extend('numeric', {
  ...numeric,
  message: 'This field only contain number'
})
extend('pin', (val:any, key:any) => {
  const tempVal = `${val}`
  const tempKey = key[0]
  if (!isNaN(val) && tempVal.length == tempKey) return true
  return `This field must be numeric & contains exactly ${key} digits`
})

import { DeleteDetail, Get, Post } from '@/api/rekapSuratJalan'

import { UploadImage } from '@/api/upload'
const onUploadImage = async (image:any) => {
  try {
    const { data } = await UploadImage(image)
    return data.data
  } catch (e:any) {
    return ''
  }
}
//#region STATE
const state = () => ({
  options: {
    page: 1,
    itemsPerPage: 25,
    sortBy: [ 'createdAt' ],
    sortDesc: [ true ],
  },
  date: { start: '', end: '' },
  search: '',
  data: { data: [], totalRecords: 0, totalPages: 0 },
  existData: 0
})
//#endregion
//#region MUTATIONS
const mutations = {
  SET_OPTIONS: (state:any, payload:any) => state.options = payload,
  SET_SEARCH: (state:any, payload:any) => state.search = payload,
  SET_DATE: (state:any, payload:any) => state.date = payload,
  SET_DATA: (state:any, payload:any) => state.data = payload,
  SET_EXIST_DATA: (state:any, payload:any) => state.existData = payload,
}
//#endregion
//#region GETTERS
const getters = {
  options: (state:any) => state.options,
  search: (state:any) => state.search,
  date: (state:any) => state.date,
  data: (state:any) => state.data,
  existData: (state:any) => state.existData,
}
//#endregion
//#region ACTIONS
const actions = {
  OnOptions({ commit, dispatch }:any, payload:any) {
    commit('SET_OPTIONS', payload)
    setTimeout(() => dispatch('ReadData'), 300)
  },
  OnSearch({ commit }:any, payload:any) {
    commit('SET_SEARCH', payload)
  },
  OnDate({ commit, dispatch }:any, payload:any) {
    commit('SET_DATE', payload)
    if (!payload.start || !payload.end) return
    setTimeout(() => dispatch('ReadData'), 300)
  },
  async ReadData({ commit, getters }:any) {
    commit('CLEAR_ERROR', null, { root: true })
    commit('SET_LOADING', true, { root: true })
    try {
      const { page, itemsPerPage, sortBy, sortDesc } = getters.options
      const { start, end } = getters.date
      const search = getters.search
      const payload = {
        sortBy: sortBy[0],
        isDescending: sortDesc[0],
        page: page,
        rowsPerPage: itemsPerPage,
        search: search,
        dateFrom: start,
        dateUntil: end,
      }
      const { data } = await Get(payload)
      commit('SET_DATA', data.data)
    } catch (e:any) {
      commit('SET_ERROR', e.response.data, { root: true })
    } finally {
      setTimeout(() => commit('SET_LOADING', false, { root: true }), 500)
    }
  },
  async CreateData({ commit, dispatch }:any, payload:any) {
    commit('CLEAR_ERROR', null, { root: true })
    commit('SET_LOADING', true, { root: true })
    try {
      //#region .NET Upload
      if (payload.signature && typeof(payload.signature) === 'object') {
        payload.signature = await onUploadImage(payload.signature)
      }
      payload.signature = typeof(payload.signature) === 'string' && payload.signature.length ? payload.signature : null
      //#endregion
      const { data } = await Post(payload)
      dispatch('ReadData')
    } catch (e:any) {
      commit('SET_ERROR', e.response.data, { root: true })
    } finally {
      setTimeout(() => commit('SET_LOADING', false, { root: true }), 500)
    }
  },
  async DeleteDataDetail({ commit, dispatch }:any, payload:any) {
    commit('CLEAR_ERROR', null, { root: true })
    commit('SET_LOADING', true, { root: true })
    try {
      const { data } = await DeleteDetail(payload.id)
      dispatch('ReadData')
    } catch (e:any) {
      commit('SET_ERROR', e.response.data, { root: true })
    } finally {
      setTimeout(() => commit('SET_LOADING', false, { root: true }), 500)
    }
  },
}
//#endregion
//#region EXPORT
export default {
  namespaced: true, state, mutations, actions, getters
}
//#endregion

import Vue from 'vue'
import Vuex from 'vuex'
import identity from './identity'
import master from './master'
import scan from './scan'
import shared from './shared'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: null,
    snackbar: { show: false, text: '' },
    loading: false,
    overlay: false,
    roles: [],
    detailRoles: [],
  },
  mutations: {
    SET_ERROR: (state, payload) => state.error = payload,
    CLEAR_ERROR: (state) => state.error = null,
    SET_SNACKBAR: (state, payload) => state.snackbar = payload,
    SET_LOADING: (state, payload) => state.loading = payload,
    SET_OVERLAY: (state, payload) => state.overlay = payload,
    SET_ROLES: (state, payload) => state.roles = payload,
    SET_DETAIL_ROLES: (state, payload) => state.detailRoles = payload,
  },
  getters: {
    error: state => state.error,
    snackbar: state => state.snackbar,
    loading: state => state.loading,
    overlay: state => state.overlay,
    roles: state => state.roles,
    detailRoles: state => state.detailRoles,
  },
  actions: {
    clearError({ commit }) {
      commit('CLEAR_ERROR')
      commit('SET_SNACKBAR', { show: false, text: '' })
    },
    setError({ commit }, payload) {
      commit('SET_ERROR', payload)
    },
  },
  modules: {
    identity,
    shared,
    scan: {
      modules: { scan },
      namespaced: true
    },
    master: {
      modules: { master },
      namespaced: true
    },
  }
})































































import { Component, Vue } from 'vue-property-decorator'
import localStorage from '@/config/local-storage'
const ls = new localStorage(process.env.VUE_APP_LS)

@Component
export default class Navigation extends Vue {
  //#region COMPUTED
  get listNav() {
    return this.$store.getters.roles
  }
  get loading() {
    return this.$store.getters.loading
  }
  get overlay() {
    return this.$store.getters.overlay
  }
  get user() {
    return this.$store.getters.user
  }
  get name() {
    return this.user ? this.user.fullname : ''
  }
  get username() {
    return this.user ? this.user.username : ''
  }
  get logo() {
    if (!this.user) return
    const logo = ls.getMerchant().logo
    return logo ? `${this.$store.getters.imageURL}/${logo}`: require('@/assets/logo.png')
  }
  get check() {
    return this.$route.name === 'Signin' || this.$route.name === 'Signup'
  }
  //#endregion
  //#region DATA
  value = 1
  drawer:boolean = false
  windowSize:any = { width: 0, height: 0 }
  //#endregion
  //#region METHODS
  onSignout() {
    this.drawer = false
    this.$store.dispatch('UserSignout')
    this.$router.push('/signin')
  }
  // handleResize() {
  //   this.windowSize.width = window.innerWidth
  //   this.windowSize.height = window.innerHeight
  // }
  //#endregion
  //#region CREATED
  created() {
    // window.addEventListener('resize', this.handleResize)
    // this.handleResize()
    // this.$nextTick(() => this.drawer = this.windowSize.width >= 1264 ? true : false)
  }
  //#endregion
}






























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Alert extends Vue {
  //#region COMPUTED
  get error() {
    return this.$store.getters.error
  }
  get message() {
    if (this.error.message) return this.error.message.toUpperCase()
    return JSON.stringify(this.error)
  }
  get snackbar() {
    return this.error ? true : false
  }
  get color() {
    if (this.error.message) return this.error.error ? `error` : `success`
    return `error`
  }
  set snackbar(val) {
    this.onDismissed()
  }
  //#endregion
  //#region METHODS
  onDismissed() {
    console.log('Dismissed Alert!')
    this.$store.dispatch('clearError')
  }
  //#endregion
}

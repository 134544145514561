import Api from './index'
const Axios = new Api().axios
const V1 = 'api/v1'
const Get = (data:any) => Axios.get(`${V1}/tracking-rekapsj`, { params: data })
const Post = (data:any) => Axios.post(`${V1}/tracking-rekapsj`, data)
const DeleteDetail = (id:number) => Axios.delete(`${V1}/tracking-rekapsj/${id}/detail`)

export {
  Get, Post,
  DeleteDetail,
}

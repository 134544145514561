import Api from './index'
const Axios = new Api().axios
const V1 = 'api/v1'
const TrackingRoles = () => Axios.get(`${V1}/shared/tracking-roles`)
const TrackingSuratJalan = () => Axios.get(`${V1}/shared/tracking-sj`)
const TrackingUserList = () => Axios.get(`${V1}/shared/tracking-user/list`)

export {
  TrackingRoles, TrackingSuratJalan, TrackingUserList
}

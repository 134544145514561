import Api from './index'
const Axios = new Api().axios
const V1 = 'api/v1'
const GetCart = () => Axios.get(`${V1}/tracking-rsj/cart`)
const Get = (data:any) => Axios.get(`${V1}/tracking-rsj`, { params: data })
const Post = (data:any) => Axios.post(`${V1}/tracking-rsj`, data)
const Put = (id:number, data:any) => Axios.put(`${V1}/tracking-rsj/${id}`, data)
const Delete = (id:number) => Axios.delete(`${V1}/tracking-rsj/${id}`)
const PostScan = (data:any) => Axios.post(`${V1}/tracking-rsj/scan`, data)
const Exist = (data:any) => Axios.post(`${V1}/tracking-rsj/exist`, data)

export {
  GetCart, Get, Post, Put, Delete, PostScan, Exist
}

import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import authGuard from './auth-guard'
import store from '@/store'
const Signin = () => import('@/views/Signin.vue')
import Page404 from '@/views/Page404.vue'

const listDefault = [
  { path: '', name: 'home', component: () => import('@/views/Home.vue') },
]
const listTransaction = [
  { path: '', name: 'TransactionIndex',
    component: () => import('@/views/transaction/Index.vue') },
  { path: 'df15e250-5610-489c-a973-e4eb29aae0bc', name: 'TransactionListReturSuratJalan',
    component: () => import('@/views/transaction/ListReturSuratJalan.vue') },
]
const listScan = [
  { path: '', name: 'ScanIndex',
    component: () => import('@/views/scan/Index.vue') },
  { path: '3f8bd6bd-31fa-429c-917c-8921e56ecddc', name: 'ScanScanSuratJalan',
    component: () => import('@/views/scan/ScanSuratJalan.vue') },
  { path: '5f320e55-2d63-4f91-82ea-2c9ed2d8951e', name: 'ScanScanReturSuratJalan',
    component: () => import('@/views/scan/ScanReturSuratJalan.vue') },
  { path: '7b2eb8a9-794d-4ce5-aeac-ebadcfd244b1', name: 'ScanRekapSuratJalan',
    component: () => import('@/views/scan/RekapSuratJalan.vue') },
]
const listReport = [
  { path: '', name: 'ReportIndex',
    component: () => import('@/views/report/Index.vue') },
  { path: '36074894-fd49-418d-bde3-14730fadba66', name: 'ReportSuratJalan',
    component: () => import('@/views/report/SuratJalan.vue') },
  { path: 'c2ca6fb5-9ed8-4854-b94a-d3d48cd71130', name: 'ReportReturSuratJalan',
    component: () => import('@/views/report/ReturSuratJalan.vue') },
  { path: 'c87032be-5949-49de-9ef4-0aa7e967fa03', name: 'ReportScanSuratJalanUserDivisi',
    component: () => import('@/views/report/ScanSuratJalanUserDivisi.vue') },
]
const listMaster = [
  { path: '', name: 'MasterIndex',
    component: () => import('@/views/master/Index.vue') },
  { path: '563648d0-94e7-4c2d-b643-797a54927345', name: 'MasterTrackingUserProfile',
    component: () => import('@/views/master/TrackingUserProfile.vue') },
  { path: 'f48021f4-e523-407a-8075-b25134d3d93f', name: 'MasterTrackingUser',
    component: () => import('@/views/master/TrackingUser.vue') },
  { path: '14893907-7ca1-45cf-b41d-6b1e1df5303a', name: 'MasterTrackingProduct',
    component: () => import('@/views/master/TrackingProduct.vue') },
]

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: { render(c) { return c('router-view') } },
    children: listDefault,
    beforeEnter: authGuard
  },
  {
    path: '/transaction',
    component: { render(c) { return c('router-view') } },
    children: listTransaction,
    beforeEnter: authGuard
  },
  {
    path: '/scan',
    component: { render(c) { return c('router-view') } },
    children: listScan,
    beforeEnter: authGuard
  },
  {
    path: '/report',
    component: { render(c) { return c('router-view') } },
    children: listReport,
    beforeEnter: authGuard
  },
  {
    path: '/master',
    component: { render(c) { return c('router-view') } },
    children: listMaster,
    beforeEnter: authGuard
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin,
  },
  {
    path: '*',
    component: Page404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('SET_OVERLAY', true)
  next()
})

router.afterEach(() => {
  setTimeout(() => { store.commit('SET_OVERLAY', false)}, 500)
})

export default router

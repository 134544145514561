import Vue from 'vue'
import Moment from 'moment'

// set format
Vue.filter('datetime', (value:any) => Moment(value).format('DD-MM-YYYY HH:mm:ss'))
Vue.filter('datetime1', (value:any) => Moment(value).format('DD-MMM-YYYY HH:mm'))
Vue.filter('datetime2', (value:any) => Moment(value).format('DD MMM YY HH:mm'))
Vue.filter('date', (value:any) => Moment(value).format('DD-MM-YYYY'))
Vue.filter('date1', (value:any) => Moment(value).format('DD-MMM-YYYY'))
Vue.filter('time', (value:any) => Moment(value).format('HH:mm:ss'))
Vue.filter('time1', (value:any) => Moment(value).format('HH:mm'))
Vue.filter('txtMonthYear', (value:any) => Moment(value).format('MMM YYYY'))
Vue.filter('txtYearMonth', (value:any) => Moment(value).format('YYYY-MM'))
Vue.filter('custom', (format:string, value:any) => Moment(value).format(format))
// set form
Vue.filter('fdate', (value:any) => Moment(value).format('YYYY-MM-DD'))
Vue.filter('fdatetime', (value:any) => Moment(value).format('YYYY-MM-DD HH:mm'))
// set default
Vue.filter('startSubtract', (value:number) => Moment().subtract(value, 'month').startOf('month').format('YYYY-MM-DD'))
Vue.filter('endSubtract', (value:number) => Moment().subtract(value, 'month').endOf('month').format('YYYY-MM-DD'))
Vue.filter('startAdd', (value:number) => Moment().add(value, 'month').startOf('month').format('YYYY-MM-DD'))
Vue.filter('endAdd', (value:number) => Moment().add(value, 'month').endOf('month').format('YYYY-MM-DD'))
Vue.filter('startOfWeek', () => Moment().startOf('week').format('YYYY-MM-DD'))
Vue.filter('endOfWeek', () => Moment().endOf('week').format('YYYY-MM-DD'))
Vue.filter('startOfMonth', () => Moment().startOf('month').format('YYYY-MM-DD'))
Vue.filter('endOfMonth', () => Moment().endOf('month').format('YYYY-MM-DD'))
Vue.filter('startOfYear', () => Moment().startOf('year').format('YYYY-MM-DD'))
Vue.filter('endOfYear', () => Moment().endOf('year').format('YYYY-MM-DD'))
Vue.filter('currentMonth', () => Moment().format('YYYY-MM'))
// duration
Vue.filter('duration', (type:string, start:any, end:any) => {
  if (!start || !end) return
  var m1 = Moment(start)
  var m2 = Moment(end)
  var m3 = m2.diff(m1,'minutes')

  var numdays = Math.floor(m3 / 1440)
  var numhours = Math.floor((m3 % 1440) / 60)
  var numminutes = Math.floor((m3 % 1440) % 60)
  return `${numdays}d ${numhours}h ${numminutes}m`
})

import Api from './index'

export default class Identity extends Api {
  constructor() {
    super()
  }

  async Expired() {
    this.Authorize()
    try {
      await this.axios.get(`api/v1/user/expired`)
      return false
    } catch (e) {
      return true
    }
  }
  async Signin(data:any) {
    return await this.axios.post(`api/v1/user/signin`, data)
  }
}

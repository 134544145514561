import Axios from 'axios'
import { baseURL } from '@/config/index'
import localStorage from '@/config/local-storage'

export default class Api {
  axios:any
  cache:any

  constructor() {
    this.axios = Axios
    this.axios.defaults.baseURL = process.env.VUE_APP_API || baseURL
    this.cache = new localStorage(process.env.VUE_APP_LS)
  }

  Authorize() {
    this.axios.defaults.headers.common['Authorization'] = `Bearer ${this.cache.getToken()}`
  }
}

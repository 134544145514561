import Vue from 'vue'
import Numeral from 'numeral'

// http://numeraljs.com/
Numeral.register('locale', 'id', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: (number:number) => {
    switch (number) {
      case 1: return 'st'
      case 2: return 'nd'
      case 3: return 'rd'
      default: return 'th'
    }
  },
  currency: {
    symbol: '$'
  }
})
// switch between locales
Numeral.locale('id')

Vue.use(Numeral)
Vue.filter('number', (value:any) => Numeral(value).format('0,0'))
Vue.filter('fx', (value:any) => Numeral(value).format('0.00000'))
Vue.filter('fx1', (value:any) => Numeral(value).format('0.0000'))
Vue.filter('yen', (value:any) => Numeral(value).format('0.000'))
Vue.filter('yen1', (value:any) => Numeral(value).format('0.00'))
Vue.filter('xau', (value:any) => Numeral(value).format('0.0000'))
// Vue.filter('currency', (value:any) => Numeral(value).format('$ 0,0'))
Vue.filter('currency', (value:any) => Numeral(value).format('$ 0,0.00'))
Vue.filter('percent', (value:any) => Numeral(value).format('0.00%'))
Vue.filter('value', (value:any) => Numeral(value).value())

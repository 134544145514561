

































import { Component, Vue } from 'vue-property-decorator'
import Navigation from '@/components/Navigation.vue'
import BottomNavigation from '@/components/BottomNavigation.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import Alert from '@/components/Alert.vue'

@Component({
  components: {
    'AppBread': Breadcrumb,
    'AppNav': Navigation,
    'AppBottomNav': BottomNavigation,
    'AppAlert': Alert,
  }
})
export default class App extends Vue {
  //#region COMPUTED
  get check() {
    return this.$route.name === 'Signin' || this.$route.name === 'Signup'
  }
  //#endregion
  //#region DATA
  registration:any = null
  refreshing:boolean = false
  updateExists:boolean = false
  //#endregion
  //#region METHODS
  updateAvailable(event:any) {
    this.registration = event.detail
    this.updateExists = true
  }
  refreshApp() {
    this.updateExists = false
    if (!this.registration || !this.registration.waiting) return
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
  }
  //#endregion
  //#region CREATED
  created() {
    document.onkeydown = function(e) {
      if (e.ctrlKey && e.which == 83) return false
    }

    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  }
  //#endregion
}

import URL from '@/api/identity'
const Api = new URL()
//#region STATE
const state = () => ({
  user: null,
})
//#endregion
//#region MUTATIONS
const mutations = {
  SET_USER: (state:any, payload:any) => state.user = payload,
}
//#endregion
//#region GETTERS
const getters = {
  user: (state:any) => state.user,
}
//#endregion
//#region ACTIONS
const actions = {
  async UserSignin({ commit }:any, payload:any) {
    commit('CLEAR_ERROR')
    commit('SET_LOADING', true)
    try {
      const { data } = await Api.Signin(payload)
      Api.cache.set(JSON.stringify(data.data))
      commit('SET_USER', Api.cache.getUser())
      commit('SET_ROLES', Api.cache.getRoles())
      commit('SET_DETAIL_ROLES', Api.cache.getDetailRoles())
    } catch (e:any) {
      commit('SET_ERROR', e.response.data)
    } finally {
      setTimeout(() => { commit('SET_LOADING', false)}, 500)
    }
  },
  UserAutoSignin({ commit }:any) {
    const token:string = Api.cache.getToken()
    if (token.length) {
      commit('SET_USER', Api.cache.getUser())
      commit('SET_ROLES', Api.cache.getRoles())
      commit('SET_DETAIL_ROLES', Api.cache.getDetailRoles())
    }
  },
  UserSignout({ commit }:any) {
    Api.cache.removeToken()
    commit('CLEAR_ERROR')
    commit('SET_LOADING', false)
    commit('SET_USER', null)
    commit('SET_ROLES', [])
    commit('SET_DETAIL_ROLES', [])
  },
}
//#endregion
//#region EXPORT
export default {
  state, mutations, actions, getters
}
//#endregion

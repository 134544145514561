










import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BottomNavigation extends Vue {
  //#region COMPUTED
  get bottomNavs() {
    return this.$store.getters.roles.filter((x:any) => x.isMobile)
  }
  get value() {
    const splitPath = this.$route.path.split('/')
    const nav = this.bottomNavs.find((x:any) => x.route === splitPath[1])
    return nav ? true : false
  }
  //#endregion
}

import { TrackingRoles, TrackingSuratJalan, TrackingUserList } from '@/api/shared'

//#region STATE
const state = () => ({
  trackingRoles: [],
  trackingSuratJalan: [],
  trackingUserList: [],
})
//#endregion
//#region MUTATIONS
const mutations = {
  SHARED_TRACKING_ROLES: (state:any, payload:any) => state.trackingRoles = payload,
  SHARED_TRACKING_SJ: (state:any, payload:any) => state.trackingSuratJalan = payload,
  SHARED_TRACKING_USER_LIST: (state:any, payload:any) => state.trackingUserList = payload,
}
//#endregion
//#region GETTERS
const getters = {
  sharedTrackingRoles: (state:any) => state.trackingRoles,
  sharedTrackingSuratJalan: (state:any) => state.trackingSuratJalan,
  sharedTrackingUserList: (state:any) => state.trackingUserList,
}
//#endregion
//#region ACTIONS
const actions = {
  async ReadSharedTrackingRoles({ commit }:any) {
    const { data } = await TrackingRoles()
    commit('SHARED_TRACKING_ROLES', data.data)
  },
  async ReadSharedTrackingSuratJalan({ commit }:any) {
    const { data } = await TrackingSuratJalan()
    commit('SHARED_TRACKING_SJ', data.data)
  },
  async ReadSharedTrackingUserList({ commit }:any) {
    const { data } = await TrackingUserList()
    commit('SHARED_TRACKING_USER_LIST', data.data)
  },
}
//#endregion
//#region EXPORT
export default {
  state, mutations, actions, getters
}
//#endregion
